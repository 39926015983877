// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//http://localhost:8443/api/
export const environment = {
  ADMINISTRATION_API_URL: 'https://www.a2hsm.com/api/',
  STRUCTURE_API_URL: 'https://www.a2hsm.com/api/',
  ACHATS_API_URL: 'https://www.a2hsm.com/api/',
  VENTE_API_URL: 'https://www.a2hsm.com/api/',
  STOCK_API_URL: 'https://www.a2hsm.com/api/',
  REGLEMENT_API_URL: 'https://www.a2hsm.com/api/',
  AUTH_API_URL: 'https://www.a2hsm.com/api/',
  BOUTIQUE_API_URL: 'https://www.a2hsm.com/api/',
  E_COMMERCE_API_URL: 'https://www.a2hsm.com/api/',
  IMPORT_API_URL: 'https://www.a2hsm.com/api/',
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
