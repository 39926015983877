import { Component, OnInit } from "@angular/core";
import { NavigationService } from "src/app/shared/services/navigation.service";
import { SearchService } from "src/app/shared/services/search.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-sidebar-compact",
  templateUrl: "./header-sidebar-compact.component.html",
  styleUrls: ["./header-sidebar-compact.component.scss"]
})
export class HeaderSidebarCompactComponent implements OnInit {
  notifications: any[];
  username: string;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private router: Router
  ) {
    this.notifications = [
      /*{
        icon: "i-Speach-Bubble-6",
        title: "Nouveaux messages",
        badge: "0",
        text: "",
        time: new Date(),
        status: "primary",
        link: "/chat"
      }*/
    ];
  }

  ngOnInit() {
    const userDetails: any = JSON.parse(localStorage.getItem("user-details"));
    this.username = userDetails.userName;
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    state.sidenavOpen = !state.sidenavOpen;
    state.childnavOpen = !state.childnavOpen;
  }

  signout() {
    this.auth.signout();
  }

  goToProfil() {
    this.router.navigateByUrl("dashboard/user-profil")
  }
}
