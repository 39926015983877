import { TokenDetails } from './../models/token-details.model';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(private toastrService: ToastrService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        const lsTokenDetails = localStorage.getItem('user-details');
        let selectedDossierId = null;
        let headers = new HttpHeaders();

        if(lsTokenDetails != null) {
          const tokenDetails: TokenDetails = JSON.parse(lsTokenDetails);
          selectedDossierId = JSON.stringify(tokenDetails.selectedDossier?.id);
          headers = new HttpHeaders({ 'Authorization': token, 'dossier': selectedDossierId, 'Content-Type': 'application/json' });
        } else if (token != null) {
          headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        }

        request = request.clone({headers});
        return next.handle(request)
        .pipe(catchError((err) => {
          if (err.status === 403) {
            this.toastrService.error("L'accès n'est pas autorisé");
          } else {
            this.toastrService.error("Une erreur s'est produite " + err?.error?.message)
          }
          
          return err;
        }))
        .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            
          }
          return evt;
        }));
          //.pipe(finalize(() => this.loadingService.setLoading(false)));
    }
}
