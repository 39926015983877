import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/shared/services/http-client-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profil',
  templateUrl: './user-profil.component.html',
  styleUrls: ['./user-profil.component.scss']
})
export class UserProfilComponent implements OnInit {
  apiUrl = environment.ADMINISTRATION_API_URL;
  userProfil: any;
  username: string;
  selectedDossier;

  constructor(private httpClient: HttpClientService) { }

  ngOnInit(): void {
    const userDetails: any = JSON.parse(localStorage.getItem("user-details"));
    this.selectedDossier = userDetails?.selectedDossier;
    let login = userDetails.login;
    this.username = userDetails.userName;
    this.httpClient.doGet(this.apiUrl + "user_details?login=" + login).subscribe(response => {
      this.userProfil = response;
    });
    
  }

}
