import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
    role?: string;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor(private authService: AuthService) {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Accueil',
            type: 'link',
            icon: 'i-Home1',
            state: '/'
        },
        {
            name: 'Référentiel',
            description: '',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'Article', state: '/referentiel/article', type: 'link', role: 'REF_ARTICLE' },
                { icon: 'i-File-Horizontal-Text', name: 'Famille', state: '/referentiel/famille', type: 'link', role: 'REF_FAMILLE' },
                { icon: 'i-File-Horizontal-Text', name: 'Unité', state: '/referentiel/unite', type: 'link', role: 'REF_UNITE' },
                { icon: 'i-File-Horizontal-Text', name: 'Collaborateur', state: '/referentiel/collaborateur', type: 'link', role: 'REF_COLLAB' },
                { icon: 'i-File-Horizontal-Text', name: 'Fournisseur', state: '/referentiel/fournisseur', type: 'link', role: 'REF_FOURNISSEUR' },
                { icon: 'i-File-Horizontal-Text', name: 'Client', state: '/referentiel/client', type: 'link', role: 'REF_CLIENT' }, 
                { icon: 'i-File-Horizontal-Text', name: 'Mode paiement', state: '/referentiel/mode-paiement', type: 'link', role: 'REF_MODE_PAIEMENT' },
                { icon: 'i-File-Horizontal-Text', name: 'Dépôt', state: '/referentiel/depot', type: 'link', role: 'REF_DEPOT' },
                { icon: 'i-File-Horizontal-Text', name: 'Tva', state: '/referentiel/tva', type: 'link', role: 'REF_TVA' },
                { icon: 'i-File-Horizontal-Text', name: 'Condition de paiement', state: '/referentiel/condition-paiement', type: 'link', role: 'REF_CONDITION_PAIEMENT' },
                { icon: 'i-File-Horizontal-Text', name: 'Pays', state: '/referentiel/pays', type: 'link', role: 'REF_PAYS' },
                { icon: 'i-File-Horizontal-Text', name: 'Ville', state: '/referentiel/ville', type: 'link', role: 'REF_VILLE' },
                { icon: 'i-File-Horizontal-Text', name: 'Lot', state: '/referentiel/article-lot', type: 'link', role: 'REF_LOT' },
                { icon: 'i-File-Horizontal-Text', name: 'Article attributs', state: '/referentiel/article-attributs', type: 'link', role: 'REF_ARTICLE_ATTRIBUT' },
                { icon: 'i-Add-UserStar', name: 'Gestion des profils', state: 'administration/profil', type: 'link', role: 'REF_PROFIL' }
            ]
        },
        {
            name: 'Stock',
            description: '',
            type: 'dropDown',
            icon: 'i-Big-Data',
            sub: [
                { icon: 'i-Add-File', name: 'Entrée', state: 'operations/stock/entree', type: 'link', role: 'STK_DE' },
                { icon: 'i-Remove-File', name: 'Sortie', state: 'operations/stock/sortie', type: 'link', role: 'STK_DS' },
                { icon: 'i-File-Copy', name: 'Transfert', state: 'operations/stock/transfert', type: 'link', role: 'STK_DT' },
                { icon: 'i-Check', name: 'Inventaire', state: 'operations/stock/inventaire', type: 'link', role: 'STK_DI' },
            ]
        },
        {
            name: 'Achat',
            description: '',
            type: 'dropDown',
            icon: 'i-Full-Basket',
            sub: [
                { icon: 'i-Receipt-4', name: 'Demande de prix', state: 'operations/achat/devis', type: 'link', role: 'ACH_DE' },
                { icon: 'i-Receipt-4', name: 'Bon de commande', state: 'operations/achat/bc', type: 'link', role: 'ACH_BC' },
                { icon: 'i-Receipt-4', name: 'Bon de réception', state: 'operations/achat/br', type: 'link', role: 'ACH_BR' },
                { icon: 'i-Receipt-4', name: 'Facture', state: 'operations/achat/fa', type: 'link', role: 'ACH_FA' },
                { icon: 'i-Receipt-4', name: 'Facture de retour', state: 'operations/achat/fr', type: 'link', role: 'ACH_FR' },
                { icon: 'i-Receipt-4', name: "Facture d'avoir", state: 'operations/achat/faa', type: 'link', role: 'ACH_FAV' },
            ]
        },
        {
            name: 'Vente',
            description: '',
            type: 'dropDown',
            icon: 'i-Full-Cart',
            sub: [
                { icon: 'i-Receipt-4', name: 'Demande de prix', state: 'operations/vente/devis', type: 'link', role: 'VTE_DE' },
                { icon: 'i-Receipt-4', name: 'Bon de commande', state: 'operations/vente/bc', type: 'link', role: 'VTE_BC' },
                { icon: 'i-Receipt-4', name: 'Bon de livraison', state: 'operations/vente/bl', type: 'link', role: 'VTE_BL' },
                { icon: 'i-Receipt-4', name: 'Facture', state: 'operations/vente/fa', type: 'link', role: 'VTE_FA' },
                { icon: 'i-Receipt-4', name: 'Facture de retour', state: 'operations/vente/fr', type: 'link', role: 'VTE_FR' },
                { icon: 'i-Receipt-4', name: "Facture d'avoir", state: 'operations/vente/faa', type: 'link', role: 'VTE_FAV' },
            ]
        },
        {
            name: 'Réglement',
            description: '',
            type: 'dropDown',
            icon: 'i-Money-2',
            sub: [
                { icon: 'i-Money-2', name: 'Réglement client', state: 'operations/reglement/client', type: 'link', role: 'RGL_CLIENT' },
                { icon: 'i-Money-2', name: 'Réglement fournisseur', state: 'operations/reglement/fournisseur', type: 'link', role: 'RGL_FOURNISSEUR' },
            ]
        }
    ];

    administrationMenu = {
        name: 'Administration',
        description: '',
        type: 'dropDown',
        icon: 'i-Administrator',
        sub: [
            { icon: 'i-Business-Mens', name: 'Gestion des utilisateurs', state: 'administration/user', type: 'link', role: 'ADMIN_USER' },
            { icon: 'i-Folders', name: 'Gestion des dossiers', state: 'administration/dossier', type: 'link', role: 'ADMIN_DOSSIER' },
            { icon: 'i-Add-UserStar', name: 'Gestion des profils', state: 'administration/profil', type: 'link', role: 'REF_PROFIL' },
            { icon: 'i-Lock-User', name: 'Gestion des roles', state: 'administration/role', type: 'link', role: 'ADMIN_ROLE' },
            { icon: 'i-Folders', name: 'Administration des dossiers', state: 'administration/user-dossier', type: 'link', role: 'ADMIN_DOSSIER' },
            { icon: 'i-Lock-2', name: 'Administration des permissions', state: 'administration/role-permission', type: 'link', role: 'ADMIN_PERMISSION' },

        ]
    };
    importMenu = {
        name: 'Import',
        description: '',
        type: 'dropDown',
        icon: 'i-Upload',
        sub: [
            { icon: 'i-Upload1', name: 'Import article', state: 'import/article', type: 'link', role: 'IMPORT' },
            
        ]
    };


    getMenuItems() {
        let menus = this.defaultMenu;
        menus.push(this.administrationMenu);
        menus.push(this.importMenu);

        menus?.forEach(menu => {
            menu?.sub?.forEach(s => {
                if(!this.authService.hasAuthority(s.role)) {
                    menu.sub = menu.sub.filter(e => e != s);
                }
            })
        });
        return menus;
    }

    menuItems = new BehaviorSubject<IMenuItem[]>(this.getMenuItems());
    menuItems$ = this.menuItems.asObservable();
}
