import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get sources
   * @param url 
   * @param params 
   * @returns 
   */
  doGet(url: string): Observable<any> {
    return this.httpClient.get(url);
  }


  doPost(url: string, params: any): Observable<any> {
    return this.httpClient.post(url, params);
  }

  /**
   * Call API
   */
  doPut(url: string, params: any): Observable<any> {
    return this.httpClient.put(url, params);
  }

  doDelete(url: string, id: string): Observable<any> {
    return this.httpClient.delete(url + "/" + id);
  }

  doPatch(url: string, params: any): Observable<any> {
    return this.httpClient.patch(url, params);
  }

}
